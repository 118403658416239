import React from "react";

import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

import Header from "../../Components/Header";
import Footer from "../../Components/Footer";

import News from "./News";
import Blogs from "./Blogs";

const Resources = () => {
  return (
    <div>
      <Header />
      <div className="container mt-4">
        <h4 className="title mb-0">Resources</h4>
        <p className="mb-0">
          Resources to empower your Web3 know-how and supercharge your guardian
          skills to the max.
        </p>
      </div>

      <div className="container" style={{ marginTop: "30px" }}>
        <div className="row ">
          <div className="col-lg-12">
            <News />
            <Blogs />
            <br />
            <br />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Resources;
