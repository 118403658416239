import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";

import Header from "../../Components/Header";
import BulletPointsInput from "../../Components/BulletPoints";
import { apiURI } from "../../config";

import Offcanvas from "react-bootstrap/Offcanvas";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import Tooltip from "react-bootstrap/Tooltip";

import { useAddress } from "@thirdweb-dev/react";
import { useConnectAddress } from "../../Context/WalletContext";

import { useSDK } from "@thirdweb-dev/react";

const ProductValidate = ({ show, setShow, ValidateIdea, disabledItems }) => {
  const { id } = useParams();
  const sdk = useSDK();
  const disabledQuestions = disabledItems;
  const address = useAddress();

  // Validate Module
  // const walletAddress = useSelector((state) => state.constVar.wallet_address);
  const initializeQuestions = Array(9)
    .fill()
    .map(() => `Unattempted`);
  const initializeAnswers = Array(7)
    .fill()
    .map(() => 1);
  initializeAnswers.push({ comments: "", payment_type: "" });
  initializeAnswers.push({ cons: [""], pros: [""] });
  const storedAnswers = localStorage.getItem(`product-${id}`);

  const [prosData, setProsData] = useState(
    storedAnswers ? JSON.parse(storedAnswers)[0].answer[8].pros : [""]
  );
  const [consData, setConsData] = useState(
    storedAnswers ? JSON.parse(storedAnswers)[0].answer[8].cons : [""]
  );
  // const [paymentType, setPaymentType] = useState("");

  // const [questionIndexData, setQuestionIndexData] = useState(storedAnswers ? JSON.parse(storedAnswers)[1].indexData : initializeQuestions);
  const [currentQuestion, setCurrentQuestion] = useState(
    storedAnswers ? JSON.parse(storedAnswers)[2].active : 0
  );
  const [questionIndexData, setQuestionIndexData] = useState(
    storedAnswers
      ? JSON.parse(storedAnswers)[1].indexData
      : [...initializeQuestions]
  );
  const [answers, setAnswers] = useState(
    storedAnswers ? JSON.parse(storedAnswers)[0].answer : [...initializeAnswers]
  );
  // console.log(questionIndexData,currentQuestion);
  const allQuestionsAnswered = questionIndexData.every(
    (answer) => answer !== "Unattempted"
  );

  const handleClose = async (e) => {
    if (e === "Close") {
      await handleChange();
      console.log("Answers ,", answers);
      const data = [
        { answer: answers },
        { indexData: questionIndexData },
        { active: currentQuestion },
      ];
      window.localStorage.setItem(`product-${id}`, JSON.stringify(data));
      setShow(false, "Close");
    } else {
      setShow(false, "Submit");
    }
  };

  const questions = [
    {
      index: "1",
      question:
        " Based on the one-line statement provided, are you able to understand the product or service being offered?",
      endpoints: ["It's Confusing", "Clear", "Very Clear"],
      value: 1,
      answer: "New Delhi",
    },
    {
      index: "2",
      question:
        " Are you convinced that the problem in the problem statement is a valid and pressing issue that needs to be solved?",
      endpoints: ["Minor issue", "Moderate Issue", "Major issue"],
      value: 1,
      answer: "New Delhi",
    },
    {
      index: "3",
      question:
        " In your opinion, how likely is it that the given solution would succeed in solving the problem?",
      endpoints: ["Least likely", "Likely", "Most likely"],
      value: 1,
      answer: "New Delhi",
    },
    {
      index: "4",
      question: " What's your rating of the whitepaper?",
      endpoints: ["Poor", "Average", "Outstanding"],
      value: 1,
      answer: "New Delhi",
    },
    {
      index: "5",
      question: " What's your rating of the pitch deck?",
      endpoints: ["Poor", "Average", "Outstanding"],
      value: 1,
      answer: "New Delhi",
    },
    {
      index: "6",
      question:
        " What's your take on the product? Can you see yourself using it more?",
      endpoints: ["Least likely", "Likely", "Most likely"],
      value: 1,
      answer: "New Delhi",
    },
    {
      index: "7",
      question:
        " Do you believe the solution is better than existing competitors?",
      endpoints: ["Least likely", "Likely", "Most likely"],
      value: 1,
      answer: "New Delhi",
    },
    {
      index: "8",
      question:
        " What is the value (in USD) you would offer for this product/service?",
      endpoints: ["Least likely", "Likely", "Most likely"],
      value: 1,
      answer: "New Delhi",
    },
    {
      index: "9",
      question: " What's your overall take on the product?",
      endpoints: ["Least likely", "Likely", "Most likely"],
      value: 1,
      answer: "New Delhi",
    },
  ];

  const handleNext = () => {
    setCurrentQuestion(currentQuestion + 1);
    if (disabledQuestions[currentQuestion]) {
      const newData = [...questionIndexData];
      newData[currentQuestion] = "Answered";
      setQuestionIndexData(newData);
    }
  };

  const handlePrev = () => {
    setCurrentQuestion(currentQuestion - 1);
  };

  //set the answer array and  QuestionIndexData to Answered
  const handleOptionClick = (value) => {
    // console.log("Option Clicked");
    const newAnswers = [...answers];
    newAnswers[currentQuestion] = value;
    setAnswers(newAnswers);
    const newData = [...questionIndexData];
    newData[currentQuestion] = "Answered";
    setQuestionIndexData(newData);
  };

  // Navigating through the Question Numbers
  const handleQuestionChange = (index) => {
    setCurrentQuestion(index);
  };

  const handleChange = () => {
    // console.log("Handle Change Called");
    setAnswers((prevFormData) => {
      // Copy the previous formData object
      const updatedAnswers = [...prevFormData];
      // Update the 'pros' or 'cons' property of the last object in the array
      updatedAnswers[8].cons = consData;
      updatedAnswers[8].pros = prosData;

      return updatedAnswers;
      // console.log("Updated Answers Before updated",updatedAnswers)
      // console.log("Cons Data :",consData);
      // console.log("Pros Data :",prosData);
      // console.log("Updated Answers after updated",updatedAnswers)
    });
  };

  const handleTextInput = (event) => {
    // console.log("Text Clicked")
    const { name, value } = event.target;
    setAnswers((prevFormData) => {
      // Copy the previous formData object
      const updatedAnswers = [...prevFormData];
      // Update the 'pros' or 'cons' property of the last object in the array
      updatedAnswers[7][name] = value;
      return updatedAnswers;
    });
    const newData = [...questionIndexData];
    newData[currentQuestion] = "Answered";
    setQuestionIndexData(newData);
  };

  const handlePaymentChange = (event) => {
    // console.log("Payment Clicked")
    const { name, value } = event.target;
    console.log(name, value);
    setAnswers((prevFormData) => {
      // Copy the previous formData object
      const updatedAnswers = [...prevFormData];
      // Update the 'pros' or 'cons' property of the last object in the array
      updatedAnswers[7][name] = value;
      return updatedAnswers;
    });
  };

  const signMessage = async () => {
    // console.log("Signature Message Clicked ");
    // Now we can sign the message with the connected wallet
    const message = "Validating the Product For- Sentinel";
    try {
      const signature = await sdk.wallet.sign(message);
      // console.log("Signature ", signature);
      await createRatedIdea(signature);
    } catch (e) {
      console.log(e);
      alert("Please Accept the Signature Request to Submit Your Review");
    }
  };

  const handleSubmit = async () => {
    // console.log(answers);
    await handleChange();
    try {
      await signMessage();
    } catch (error) {
      console.log("Error:", error);
    }
  };
  const createRatedIdea = async (signature) => {
    // console.log("Answers :",answers,answers[7].comments);
    try {
      var query = `
          mutation Mutation($input: RatingsInput) {
              createRating(input: $input)
            }
            `;
      await fetch(apiURI.URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "x-power": process.env.REACT_APP_POWER_KEY,
          "x-domain-agent": process.env.REACT_APP_DOMAIN_AGENT,
          "x-strict-origin-name": process.env.REACT_APP_ORIGIN_NAME,
          "x-range-name": process.env.REACT_APP_RANGE_NAME,
        },
        body: JSON.stringify({
          query,
          variables: {
            input: {
              product: id,
              reviewer: address.toString().toLowerCase(),
              one_line_statement: parseInt(answers[0]),
              problem_statement: parseInt(answers[1]),
              solution_statement: parseInt(answers[2]),
              whitepaper: parseInt(answers[3]),
              pitch_deck: parseInt(answers[4]),
              product_rating: parseInt(answers[5]),
              competitive_advantage: parseInt(answers[6]),
              product_worth: `${answers[7].comments} $ / ${answers[7].payment_type}`,
              pros: answers[8].pros,
              cons: answers[8].cons,
              signature: signature,
            },
          },
        }),
      })
        .then((response) => {
          const json = response.json();
          return json;
        })
        .then(async (data) => {
          // console.log("Response :",data.data.createRatedIdea);
          if (data?.data?.createRating) {
            localStorage.removeItem(`product-${id}`);
            handleClose("Submit");
            ValidateIdea(true);
            //Clear Local Storage
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  if (questionIndexData[8] !== "Answered") {
    if (prosData.length >= 2 && consData.length >= 2) {
      const newData = [...questionIndexData];
      newData[8] = "Answered";
      setQuestionIndexData(newData);
    }
  }

  return (
    <div>
      <Offcanvas
        show={show}
        onHide={() => handleClose("Close")}
        placement={"end"}
        scroll={true}
        backdrop={true}
        className="sidebar-width"
      >
        <Offcanvas.Header
          closeButton
          bsPrefix="sidebar-closebutton"
          closeVariant="white"
        >
          <Offcanvas.Title>Validate This Product</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="offcanvas-body p-4">
            <div className="row">
              <div className="col-lg-12 col-sm-12 mb-5">
                <div className="sidebar-questions rounded shadow d-flex justify-content-between">
                  <div className="p-2">
                    {questionIndexData.map((value, index) => (
                      <>
                        <button
                          onClick={() => handleQuestionChange(index)}
                          className={
                            value === "Unattempted"
                              ? "btn btn-primary in-validate"
                              : value === "Marked"
                              ? "btn btn-warning in-validate"
                              : "btn btn-success in-validate"
                          }
                        >
                          {" "}
                          {index + 1}{" "}
                        </button>{" "}
                        <span style={{ marginRight: "0.3px" }} />
                      </>
                    ))}
                  </div>
                </div>
              </div>

              <div className="col-lg-12 col-md-12 order-2 order-md-0 mt-5 mt-sm-0 mb-5">
                <div className="section-title">
                  <h1
                    className="title qs-validate mb-3"
                    style={{ height: currentQuestion === 8 ? "50px" : "150px" }}
                  >
                    {currentQuestion + 1}.{questions[currentQuestion].question}
                  </h1>

                  {currentQuestion < 7 ? (
                    <>
                      {disabledQuestions[currentQuestion] ? (
                        <>
                          <p
                            className="ans-validate"
                            style={{ marginTop: "10px" }}
                          >
                            Data Not Provided Cannot Review this Question
                          </p>
                          <div
                            className="mt-4 d-flex"
                            style={{
                              alignItems: "flex-start",
                              height: "100px",
                            }}
                          >
                            <p style={{ width: "100px", fontSize: "15px" }}>
                              {questions[currentQuestion].endpoints[0]}
                            </p>
                            <input
                              title="My Tooltip"
                              type="range"
                              min="1"
                              max="5"
                              step="1"
                              value={answers[currentQuestion]}
                              className="slider"
                              id="myRange"
                              disabled
                            />
                            {/* onChange={(event) => handleOptionClick(event.target.value)}  */}
                            <p style={{ width: "100px", fontSize: "15px" }}>
                              {questions[currentQuestion].endpoints[2]}
                            </p>
                          </div>
                        </>
                      ) : (
                        <>
                          <p className="ans-validate">
                            Based on a 1-5 scale (1 being{" "}
                            {questions[currentQuestion].endpoints[0]} and 5
                            being {questions[currentQuestion].endpoints[2]})
                          </p>
                          <div
                            className="mt-4 d-flex"
                            style={{
                              alignItems: "flex-start",
                              height: "100px",
                            }}
                          >
                            <p style={{ width: "100px", fontSize: "15px" }}>
                              {questions[currentQuestion].endpoints[0]}
                            </p>
                            <input
                              title="My Tooltip"
                              type="range"
                              min="1"
                              max="5"
                              step="1"
                              value={answers[currentQuestion]}
                              className="slider"
                              id="myRange"
                              onChange={(event) =>
                                handleOptionClick(event.target.value)
                              }
                            />
                            <p style={{ width: "100px", fontSize: "15px" }}>
                              {questions[currentQuestion].endpoints[2]}
                            </p>
                          </div>
                        </>
                      )}

                      {/* <div  className="d-flex" style={{width:"100%"}}> */}
                      {/* <div className="slidecontainer d-flex"> */}
                      {/* </div>     */}
                      {/* </div> */}
                      {/* <label className="form-check-label rd-c" htmlFor={answers[currentQuestion]} >
                                      { answers[currentQuestion] === "3" ? questions[currentQuestion].endpoints[2] 
                                      : answers[currentQuestion] === "1"  ? questions[currentQuestion].endpoints[0] 
                                      : answers[currentQuestion] === "2"? questions[currentQuestion].endpoints[1]
                                      : ""} </label>&nbsp;&nbsp;&nbsp;&nbsp; */}
                    </>
                  ) : currentQuestion === 7 ? (
                    <div>
                      <label for="payment">
                        Choose your Payment Type : &nbsp;
                      </label>
                      <select
                        value={answers[7].payment_type}
                        onChange={handlePaymentChange}
                        name="payment_type"
                        className="btn btn-outline-secondary"
                        style={{
                          fontSize: "15px",
                          width: "auto",
                          textAlign: "center",
                        }}
                      >
                        <option value="">Select</option>
                        <option value="Yearly">Yearly</option>
                        <option value="Monthly">Monthly</option>
                        <option value="One Time Payment">One Time</option>
                      </select>
                      <br />
                      <br />
                      <FloatingLabel
                        controlId="floatingTextarea2"
                        label="Provide a Value in USD"
                      >
                        <Form.Control
                          as="input"
                          type="number"
                          placeholder="Enter value in USD"
                          value={answers[7].comments}
                          onChange={handleTextInput}
                          name="comments"
                          style={{ height: "auto" }}
                          step="0.01" // Optional: controls the increment for decimal values
                          min="0" // Optional: sets a minimum value
                        />
                      </FloatingLabel>
                    </div>
                  ) : (
                    <div>
                      <span>
                        Add Minimum 2 pros & cons to Submit your Review
                      </span>
                      <p>Pros</p>
                      <BulletPointsInput
                        parentState={prosData}
                        setParentState={setProsData}
                      />
                      <br />
                      <p>Cons</p>
                      <BulletPointsInput
                        parentState={consData}
                        setParentState={setConsData}
                      />
                    </div>
                  )}
                </div>
                <div className="col-12 mb-0">
                  <div className="mt-5 component-wrapper rounded shadow justify-content-between">
                    <div
                      className="p-4"
                      style={{ justifyContent: "center", display: "flex" }}
                    >
                      <button
                        disabled={currentQuestion === 0 ? true : false}
                        onClick={() => handlePrev()}
                        className="btn btn-primary"
                      >
                        {" "}
                        {"<"}{" "}
                      </button>
                      <span style={{ paddingRight: "4.5px" }} />
                      <button
                        style={{
                          display: allQuestionsAnswered ? "" : "none",
                          float: "right",
                        }}
                        onClick={() => handleSubmit()}
                        className="btn btn-primary btn-th"
                      >
                        Submit
                      </button>{" "}
                      <span style={{ paddingRight: "4.5px" }} />
                      <button
                        disabled={currentQuestion === 8 ? true : false}
                        onClick={() => handleNext()}
                        className="btn btn-primary"
                      >
                        {" "}
                        {">"}{" "}
                      </button>
                      <span style={{ paddingRight: "4.5px" }} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
      <br />
      <br />
      <br />
    </div>
  );
};

export default ProductValidate;
