import React, { useState, useEffect } from "react";

import { apiURI } from "../../config";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const News = () => {
  const [newsData, setNewsData] = useState([]);

  const getNewsData = async () => {
    try {
      var query = `
            query GetNewsAlpha($id: ID) {
                getNewsAlpha(_id: $id) {
                  url
                  title
                  banner_image
                }
              }
                `;
      await fetch(apiURI.URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "x-power": process.env.REACT_APP_POWER_KEY,
          "x-domain-agent": process.env.REACT_APP_DOMAIN_AGENT,
          "x-strict-origin-name": process.env.REACT_APP_ORIGIN_NAME,
          "x-range-name": process.env.REACT_APP_RANGE_NAME,
        },
        body: JSON.stringify({
          query,
          variables: {
            id: "test",
          },
        }),
      })
        .then((response) => {
          const json = response.json();
          return json;
        })
        .then(async (data) => {
          // console.log(data.data.getNewsAlpha)
          if (data?.data?.getNewsAlpha) {
            setNewsData(data.data.getNewsAlpha);
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  const default_image_url =
    "https://api.sentinel.watcher.finance/file-33621723284888139.jpg";

  useEffect(() => {
    getNewsData();
  }, []);

  var settings = {
    dots: false,
    arrows: true,
    infinite: true,
    swipeToSlide: true,
    initialSlide: 0,
    slidesToShow: 4,
    autoplay: true,
    speed: 500,
    autoplaySpeed: 3000,
    cssEase: "ease-out",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          infinite: true,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 3,
          infinite: true,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          arrows: false,
          initialSlide: 0,
          infinite: true,
        },
      },
    ],
  };

  var trendingNews = {
    dots: false,
    arrows: false,
    initialSlide: -1,
    infinite: true,
    slidesToShow: 2.5,
    slidesToScroll: 1,
    vertical: true,
    verticalSwiping: true,
    swipeToSlide: true,
    autoplay: true,
    pauseOnHover: true,
    speed: 1500,
    autoplaySpeed: 0,
    cssEase: "linear",

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2.5,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
        },
      },
    ],
  };

  return (
    <div>
      <div className="row">
        <div className="col-xl-9 col-lg-9 col-md-12 col-sm-12  order-lg-1  order-md-2 order-2">
          <Slider {...settings} style={{ marginRight: "10px" }}>
            {newsData.slice(0, 20).map((value, index) => {
              return (
                <a href={value.url} target="_blank" rel="noreferrer">
                  <div style={{ paddingRight: "10px" }} onClick={() => {}}>
                    <div className="card blog blog-primary border-0 shadow rounded overflow-hidden">
                      <div
                        className="position-relative overflow-hidden"
                        style={{ display: "contents" }}
                      >
                        <img
                          src={
                            value.banner_image === "short"
                              ? default_image_url
                              : value.banner_image
                          }
                          className="news-image"
                          alt="News_Image"
                        />
                      </div>
                      <div className="card-body content news-text">
                        <p className="text-dark  h6 news-title">
                          {value.title.length > 50
                            ? value.title.substring(0, 50) + "..."
                            : value.title}
                        </p>
                        <div className="mt-2">
                          <a
                            href={value.url}
                            target="_blank"
                            rel="noreferrer"
                            className="news-text"
                          >
                            Read more ...{" "}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              );
            })}
          </Slider>
        </div>

        <div
          className="col-xl-3 col-lg-3 col-md-12 col-sm-12  order-lg-2  order-md-1 order-1"
          style={{ marginBottom: "30px" }}
        >
          <h5>Trending News ⚡</h5>
          <br />
          <Slider {...trendingNews}>
            {newsData.slice(20, 40).map((value, index) => {
              return (
                <div>
                  <a
                    href={value.url}
                    target="_blank"
                    rel="noreferrer"
                    className="link-color"
                  >
                    <p style={{ fontSize: "small" }}>{value.title}</p>
                  </a>
                </div>
              );
            })}
          </Slider>
        </div>
      </div>
      <br />
      <br />
      <div>
        <Slider {...settings}>
          {newsData.slice(40).map((value, index) => {
            return (
              <a href={value.url} target="_blank" rel="noreferrer">
                <div style={{ paddingRight: "10px" }} onClick={() => {}}>
                  <div className="card blog blog-primary border-0 shadow rounded overflow-hidden">
                    <div
                      className="position-relative overflow-hidden"
                      style={{ display: "contents" }}
                    >
                      <img
                        src={
                          value.banner_image === "short"
                            ? default_image_url
                            : value.banner_image
                        }
                        className="news-image"
                        alt="News_Image"
                      />
                    </div>
                    <div className="card-body content news-text">
                      <p className="text-dark  h6 news-title">
                        {value.title.length > 55
                          ? value.title.substring(0, 55) + "  ...  "
                          : value.title}
                      </p>
                      <div className="mt-2">
                        <a
                          href={value.url}
                          target="_blank"
                          rel="noreferrer"
                          className="news-text"
                        >
                          Read more ...{" "}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            );
          })}
        </Slider>
      </div>
    </div>
  );
};

export default News;
