import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";

//React Bootstrap
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";

import sentinel_logo from "../images/assets/sentinel-logo.png";

import { ConnectWallet, useConnectionStatus } from "@thirdweb-dev/react";

import { notifyError } from "./Alerts";

const Header = () => {
  const connectionStatus = useConnectionStatus();
  const navigate = useNavigate();

  const handleNavigation = async (location) => {
    if (connectionStatus !== "connected") {
      notifyError("Connect your Wallet");
    } else {
      navigate(`/${location}`);
    }
  };
  return (
    <div className="header_scroll">
      <header id="topnav" className="defaultscroll sticky">
        <div className="container d-flex justify-content-between align-items-center">
          <div>
            <Link to="/" className="logo">
              <img
                src={sentinel_logo}
                height="35"
                className="logo-dark-mode"
                alt=""
              />
            </Link>
          </div>

          <div className="d-flex navbarContentsSize">
            <Navbar collapseOnSelect expand="lg" variant="dark">
              <Container>
                <Navbar.Toggle
                  aria-controls="responsive-navbar-nav"
                  style={{ marginRight: "5px" }}
                />

                <Navbar.Collapse id="responsive-navbar-nav">
                  <Nav style={{ justifyContent: "space-between" }}>
                    {window.innerWidth <= 992 ? (
                      <Navbar.Brand as={Link} to="/" bsPrefix="logo">
                        <img
                          src={sentinel_logo}
                          height="24"
                          className="logo-dark-mode"
                          alt="logo"
                        />
                      </Navbar.Brand>
                    ) : (
                      ""
                    )}
                    <ul className="navigation-menu">
                      <li>
                        <a
                          onClick={() => handleNavigation("resources")}
                          className="sub-menu-item"
                          style={{ cursor: "pointer" }}
                        >
                          Resources
                        </a>
                      </li>
                      {/* <li>
                        <a
                          onClick={() => handleNavigation("quiz")}
                          className="sub-menu-item"
                          style={{ cursor: "pointer" }}
                        >
                          Quiz
                        </a>
                      </li> */}
                      <li>
                        <a
                          onClick={() => handleNavigation("review2earn")}
                          className="sub-menu-item linear-wipe"
                          style={{ cursor: "pointer" }}
                        >
                          #Review2Earn
                        </a>
                      </li>
                      <li>
                        <a
                          onClick={() => handleNavigation("profile")}
                          className="sub-menu-item"
                          style={{ cursor: "pointer" }}
                        >
                          My Profile
                        </a>
                      </li>
                    </ul>
                  </Nav>
                </Navbar.Collapse>
              </Container>
            </Navbar>
          </div>

          <div>
            <ConnectWallet
              theme={"dark"}
              modalTitle={"Connect Wallet"}
              switchToActiveChain={true}
              modalSize={"wide"}
              className="connect_wallet"
            />
          </div>
        </div>
      </header>
    </div>
  );
};

export default Header;
