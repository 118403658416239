import React, { useState, useEffect } from "react";
import { apiURI } from "../../config";
//Page Components
import Header from "../../Components/Header";
import { useAddress } from "@thirdweb-dev/react";
import { useConnectAddress } from "../../Context/WalletContext";
import QuizPage from "./QuizPage";
import { useNavigate } from "react-router-dom";

const QuizModule = () => {
  // Redux Variables
  // const walletAddress = useSelector((state) => state.constVar.wallet_address);
  // const userData = useSelector((state) => state.constVar.user_data);

  // State Variables
  const address = useAddress();
  const { user } = useConnectAddress();
  const [quizStarted, setQuizStarted] = useState(false);
  const [questions, setQuestions] = useState([]);
  const navigate = useNavigate();

  // Api Function
  const getQuestion = async () => {
    try {
      var query = `
                  query GetQuestionSet($walletAddress: String) {
                    getQuestionSet(wallet_address: $walletAddress) {
                      difficulty_level
                      _id
                      index
                      options
                      question
                      answer
                    }
                  }   
                `;
      fetch(apiURI.URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "x-power": process.env.REACT_APP_POWER_KEY,
          "x-domain-agent": process.env.REACT_APP_DOMAIN_AGENT,
          "x-strict-origin-name": process.env.REACT_APP_ORIGIN_NAME,
          "x-range-name": process.env.REACT_APP_RANGE_NAME,
        },
        body: JSON.stringify({
          query,
          variables: {
            walletAddress: address.toString().toLowerCase(),
          },
        }),
      })
        .then((response) => {
          const json = response.json();
          return json;
        })
        .then(async (data) => {
          if (data?.data?.getQuestionSet) {
            setQuestions(data.data.getQuestionSet);
            setQuizStarted(!quizStarted);
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {}, []);

  const QuizStart = () => {
    getQuestion();
  };

  return quizStarted ? (
    <>
      <QuizPage
        quizStarted={quizStarted}
        setQuizStarted={setQuizStarted}
        questions={questions}
      />
    </>
  ) : (
    <div>
      <Header />
      <section className="bg-home d-flex align-items-center bg-img-home2">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12">
              <div className="title-heading text-center">
                {!user.level_cleared ? (
                  <>
                    {/* <h4 className="heading fw-bold text-white title-dark">
                      Stage 1
                    </h4> */}
                    <h5 className="para-desc mx-auto text-white title-dark para-steps">
                      Looks like you are not a Eligible yet.You can get started
                      by taking the test
                    </h5>
                    <div className="mt-4 pt-2">
                      <button
                        onClick={() => QuizStart()}
                        className="btn btn-primary btn-th"
                      >
                        Start the test
                      </button>
                    </div>
                  </>
                ) : (
                  <>
                    <h5 className="para-desc mx-auto text-white title-dark para-steps">
                      Congratulations you have passed the test. Head to
                      Review2Earn section to start earning tokens.
                    </h5>
                    <div className="mt-4 pt-2">
                      <button
                        className="btn btn-primary btn-th"
                        onClick={() => navigate("/review2earn")}
                      >
                        Review2Earn
                      </button>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default QuizModule;
