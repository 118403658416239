import React from "react";

//Icons
import Discord from "../images/icons/discord.png";
import FB from "../images/icons/fb.png";
import LinkedIn from "../images/icons/in.png";
import Telegram from "../images/icons/tele.png";
import Twitter from "../images/icons/twitter.png";
import Youtube from "../images/icons/youtube.png";
import medium from "../images/icons/medium.png";
import instagram from "../images/icons/in.png";

//CSS
import "./Footer.css";

const Footer = () => {
  return (
    <footer class="footer">
      <div class="footer-py-20 footer-bar">
        <div class="container text-center pt-3 pb-3">
          <div class="row align-items-center">
            <div class="col-sm-12">
              <div
                class="text-center"
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <p class="cpy" style={{ marginLeft: "0px" }}>
                  © Sentinel by Watcher.Finance 2024
                </p>
                <div style={{ float: "right", marginTop: "3px" }}>
                  {/* Telegram */}
                  <a
                    href="https://t.me/watcherfinance_official"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src={Telegram}
                      alt="Telegram_Icon"
                      className="footer-icon mr-5"
                    />
                  </a>
                  <a
                    href="https://t.me/watcherfinance_announcement"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src={Telegram}
                      alt="Telegram_Icon"
                      className="footer-icon mr-5"
                    />
                  </a>
                  {/* FaceBook */}
                  <a
                    href="https://www.facebook.com/watcherfinance/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={FB} alt="FB_Icon" className="footer-icon mr-5" />
                  </a>
                  {/* Twitter */}
                  <a
                    href="https://x.com/WAFI_Sentinel"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src={Twitter}
                      alt="Twitter_Icon"
                      className="footer-icon mr-5"
                    />
                  </a>
                  {/* Linkedin
                  <a
                    href="https://www.linkedin.com/company/cr-square-finance/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src={LinkedIn}
                      alt="Instagram_Icon"
                      className="footer-icon mr-5"
                    />
                  </a> */}
                  {/* Youtube */}
                  {/* <a
                    href="https://www.youtube.com/channel/UCfBiwNGfsIr8U9KBB02b60Q"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src={Youtube}
                      alt="Youtube_Icon"
                      className="footer-icon mr-5"
                    />
                  </a> */}
                  {/* Discord */}
                  <a
                    href="https://discord.com/invite/XtJySJQwB6"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src={Discord}
                      alt="Discord_Icon"
                      className="footer-icon mr-5"
                    />
                  </a>
                  {/* Medium */}
                  <a
                    href="https://medium.com/@WAFI_Sentinel"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src={medium}
                      alt="Discord_Icon"
                      className="footer-icon mr-5"
                    />
                  </a>
                  {/* Instagram */}
                  <a
                    href="https://www.instagram.com/watcherfinance_wafi/tagged/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src={instagram}
                      alt="Discord_Icon"
                      className="footer-icon mr-5"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
