// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer-icon {
    height: 32px;
    margin-right: 10px;
}

.banner {
    background: linear-gradient(61deg, rgba(0, 168, 251, 1) 0%, rgba(3, 33, 247, 1) 100%);
    /* linear-gradient(93.8deg, rgba(87, 83, 249, 0.8) -0.22%, rgba(208, 37, 166, 0.8) 92.15%); */
    color: white;
    font-size: 14px;
    /* margin-bottom: 30px; */
    z-index: 99;

}

.warning_image {
    height: 20px
}

@media only screen and (max-width: 500px) {
    .banner {
        padding-top: 8px;
        padding-bottom: 8px;
        font-size: 11px;
    }

    .warning_text {
        font-size: 11px;

    }

    .warning_image {
        height: 15px;
    }
}`, "",{"version":3,"sources":["webpack://./src/Components/Footer.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,qFAAqF;IACrF,6FAA6F;IAC7F,YAAY;IACZ,eAAe;IACf,yBAAyB;IACzB,WAAW;;AAEf;;AAEA;IACI;AACJ;;AAEA;IACI;QACI,gBAAgB;QAChB,mBAAmB;QACnB,eAAe;IACnB;;IAEA;QACI,eAAe;;IAEnB;;IAEA;QACI,YAAY;IAChB;AACJ","sourcesContent":[".footer-icon {\n    height: 32px;\n    margin-right: 10px;\n}\n\n.banner {\n    background: linear-gradient(61deg, rgba(0, 168, 251, 1) 0%, rgba(3, 33, 247, 1) 100%);\n    /* linear-gradient(93.8deg, rgba(87, 83, 249, 0.8) -0.22%, rgba(208, 37, 166, 0.8) 92.15%); */\n    color: white;\n    font-size: 14px;\n    /* margin-bottom: 30px; */\n    z-index: 99;\n\n}\n\n.warning_image {\n    height: 20px\n}\n\n@media only screen and (max-width: 500px) {\n    .banner {\n        padding-top: 8px;\n        padding-bottom: 8px;\n        font-size: 11px;\n    }\n\n    .warning_text {\n        font-size: 11px;\n\n    }\n\n    .warning_image {\n        height: 15px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
