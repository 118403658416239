import React, { useState, useEffect } from "react";

import { apiURI } from "../../config";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Blogs = () => {
  const [articles, setArticles] = useState([]);

  const getArticles = async () => {
    try {
      var query = `
        query GetArticles($id: ID) {
          getArticles(_id: $id) {
            title
            author
            image_url
            content
            url
          }
        }
            `;
      await fetch(apiURI.URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "x-power": process.env.REACT_APP_POWER_KEY,
          "x-domain-agent": process.env.REACT_APP_DOMAIN_AGENT,
          "x-strict-origin-name": process.env.REACT_APP_ORIGIN_NAME,
          "x-range-name": process.env.REACT_APP_RANGE_NAME,
        },
        body: JSON.stringify({
          query,
          variables: {
            id: "test",
          },
        }),
      })
        .then((response) => {
          const json = response.json();
          return json;
        })
        .then(async (data) => {
          // console.log(data.data.getArticles)
          if (data?.data?.getArticles) {
            setArticles(data.data.getArticles);
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  const mediumBlogs = [
    {
      title: "Understanding Web3 and Its Impact on Financial Transparency",
      description: "",
      time: "1",
      image: "https://watcher.finance/static/assets/img-watcher/blog-1.jpg",
      url: "https://watcher.finance/blog-1-understanding-web3-and-its-impact-on-financial-transparency/",
    },

    {
      title:
        "How Sentinel Empowers Founders with Product Validation and Analytics",
      description: "",
      time: "4",
      image: " https://watcher.finance/static/assets/img-watcher/blog-2.jpg",
      url: " https://watcher.finance/blog-2-how-sentinel-empowers-founders-with-product-validation-and-analytics/",
    },

    {
      title:
        "The Role of Verified Reviewers in Enhancing Product Trust and Quality",
      description: "",
      time: "4",
      image: " https://watcher.finance/static/assets/img-watcher/blog-3.jpg",
      url: " https://watcher.finance/blog-3-the-role-of-verified-reviewers-in-enhancing-product-trust-and-quality/",
    },

    {
      title:
        "Token Rewards: Incentivizing Quality Feedback in the Web3 Ecosystem",
      description: "",
      time: "4",
      image: " https://watcher.finance/static/assets/img-watcher/blog-4.jpg",
      url: " https://watcher.finance/blog-4-token-rewards/",
    },

    {
      title: "Maximizing Your Experience on the Watcher Finance Platform",
      description: " ",
      time: "5",
      image: " https://watcher.finance/static/assets/img-watcher/blog-5.jpg",
      url: " https://watcher.finance/blog-5-maximizing-your-experience-on-the-watcher-finance-platform/",
    },

    {
      title: "The Future of Decentralized Finance: Trends and Predictions",
      description: "",
      time: "4",
      image: " https://watcher.finance/static/assets/img-watcher/blog-6.jpg",
      url: " https://watcher.finance/blog-6-the-future-of-decentralized-finance/",
    },
  ];

  var settings = {
    dots: false,
    infinite: true,
    swipeToSlide: true,
    initialSlide: 0,
    slidesToShow: 4,
    autoplay: true,
    speed: 500,
    autoplaySpeed: 3000,
    cssEase: "ease-out",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          infinite: true,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 3,
          infinite: true,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          arrows: false,
          initialSlide: 0,
          infinite: true,
        },
      },
    ],
  };

  useEffect(() => {
    getArticles();
  }, []);

  return (
    <div>
      <Slider {...settings}>
        {mediumBlogs.map((value, index) => {
          return (
            <a href={value.url} target="_blank" rel="noreferrer">
              <div style={{ paddingRight: "10px" }}>
                <div className="card blog blog-primary border-0 shadow rounded overflow-hidden">
                  <div
                    className="position-relative overflow-hidden"
                    style={{ display: "contents" }}
                  >
                    <img src={value.image} className="news-image" alt="Image" />
                  </div>
                  <div className="card-body content blog-text">
                    <p className="text-dark  h6 news-title">{value.title}</p>
                    <div className="py-3">
                      <a href="shop-blog-detail.html" className="blog-text">
                        Read more
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </a>
          );
        })}
      </Slider>
      <br />
      <br />
      <Slider {...settings}>
        {articles.map((value, index) => {
          return (
            <a href={value.url} target="_blank" rel="noreferrer">
              <div style={{ paddingRight: "10px" }} onClick={() => {}}>
                <div className="card blog blog-primary border-0 shadow rounded overflow-hidden">
                  <div
                    className="position-relative overflow-hidden"
                    style={{ display: "contents" }}
                  >
                    <img
                      src={value.image_url}
                      className="news-image"
                      alt="Image_news"
                    />
                  </div>
                  <div className="card-body content blog-text">
                    <p className="text-dark  h6 news-title">
                      {value.title.length > 50
                        ? value.title.substring(0, 50) + "..."
                        : value.title}
                    </p>
                    <div className="mt-2">
                      <a className="blog-text">
                        {value.author.length > 15
                          ? value.author.substring(0, 50) + "..."
                          : value.author}
                      </a>
                      <br />
                      <br />
                      <a href="shop-blog-detail.html" className="blog-text">
                        Read more ...{" "}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </a>
          );
        })}
      </Slider>
      <br />
    </div>
  );
};

export default Blogs;
