import React from "react";
import { Link, useNavigate } from "react-router-dom";

// Page Components
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";

import { useConnectionStatus } from "@thirdweb-dev/react";

import { notifyError } from "../../Components/Alerts";

const HomePage = () => {
  const connectionStatus = useConnectionStatus();
  const navigate = useNavigate();

  const handleNavigation = async (location) => {
    if (connectionStatus !== "connected") {
      notifyError("Connect your Wallet");
    } else {
      navigate(`/${location}`);
    }
  };

  return (
    <div>
      <Header />
      <section className="bg-home d-flex align-items-center bg-img-home">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12">
              <div className="title-heading text-center">
                <h4 className="heading fw-bold  title-dark lp-head welcome-text">
                  Welcome to Sentinel
                </h4>
                <h5
                  className="mx-auto  title-dark lp-para"
                  style={{ fontWeight: "400" }}
                >
                  Showcase your web3 expertise, earn airdrops, rewards and early
                  access to innovative dapps
                </h5>
                <div className="mt-4 pt-2">
                  <br />

                  <button
                    onClick={() => handleNavigation("review2earn")}
                    className="btn btn-primary btn-th"
                    style={{ cursor: "pointer" }}
                  >
                    #Review2Earn
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default HomePage;
