import React, { useEffect, useState } from "react";
import Header from "../../Components/Header";

import { useNavigate, useLocation } from "react-router-dom";

import Products from "./Products";

const Review2Earn = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {}, [location]);

  return (
    <div>
      <Header />
      <div class="container mt-4">
        <div class="row justify-content-center">
          <div class="col-12">
            <div class="section-title text-left mb-4 pb-2">
              <h4 class="title mb-4">Review2Earn</h4>
              <p className="mb-0">
                Review products to accumulate coins and claim awesome rewards.
                Your opinions make a difference and brings you exclusive
                benefits!
              </p>
              <div class="row">
                <div class="col-lg-12">
                  <Products />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Review2Earn;
