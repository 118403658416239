import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Header from "../../Components/Header";

import { Link } from "react-router-dom";
import { apiURI } from "../../config";
import logo from "../../images/assets/sentinel-logo.png";
import { useAddress } from "@thirdweb-dev/react";
import { useConnectAddress } from "../../Context/WalletContext";
import { notifyError } from "../../Components/Alerts";

import ProductValidate from "./ProductValidate";

const ProductDetail = () => {
  const { id } = useParams();

  const address = useAddress();
  const { user } = useConnectAddress();

  // // console.log("Connect Wallet 1234 ",connectWallet,walletAddress);
  const [show, setShow] = useState(false);
  const [validatePopup, setValidatePopup] = useState(false);
  const [productData, setProductData] = useState([]);
  const [invalidProduct, setInvalidProduct] = useState(false);
  const navigate = useNavigate();

  const isValidArray = (arr) =>
    Array.isArray(arr) && arr.length > 0 && arr[0] !== "";

  const handleClose = (e) => {
    setShow(false);
  };
  const handleShow = (e) => {
    // console.log("Handle Show," ,connectWallet)
    if (!user.level_cleared) {
      // alert("Become a Pawsifier to Review 🫡")
      notifyError("Clear Test to Review");
    } else {
      setShow(true);
    }
  };

  const showPopup = () => {
    setValidatePopup(true);
  };

  const checkDisabledItems = (data) => {
    // Initialize the disabledItems array with false values
    const disabledItems = [false, false, false, false, false, false, false];

    // List of properties to check in the data object
    const properties = [
      "problem_statement", // Index 1
      "problem_solution", // Index 2
      "whitepaper_link", // Index 3
      "pitch_deck_link", // Index 4
      "competition_reason", // Index 6
    ];

    // Check if property exists and meets the condition for being disabled
    properties.forEach((property, index) => {
      if (
        data[property] === null ||
        data[property] === undefined ||
        data[property] === "" ||
        (Array.isArray(data[property]) &&
          (data[property].length === 0 || data[property][0] === ""))
      ) {
        const disabledIndex =
          index === 0
            ? 1
            : index === 1
            ? 2
            : index === 2
            ? 3
            : index === 3
            ? 4
            : 6; // Modified to correctly handle the new property
        disabledItems[disabledIndex] = true;
      }
    });

    // console.log("DISABLED", disabledItems);
    return disabledItems;
  };
  const disabledItems = checkDisabledItems(productData);

  const handleValidatePage = () => {
    navigate("/quiz");
  };

  useEffect(() => {
    const getProductDetails = async () => {
      await getProductDetailsApi();
    };
    getProductDetails();
  }, [address]);

  const getProductDetailsApi = async () => {
    try {
      var query = `
          query GetProductDetailsByReviewer($walletAddress: String!, $product: ID!) {
              getProductDetailsByReviewer(
                wallet_address: $walletAddress
                product: $product
              ) {
                product_name
                product_logo
                product_type
                one_line_description
                category {
                  value
                }
                pitch_deck_link
                whitepaper_link
                live_product_link
                competition_links
                competition_reason
                revenue_model {
                  value
                }
                problem_statement
                problem_solution
                target_customers
                validated
              }
            }
              `;
      await fetch(apiURI.URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "x-power": process.env.REACT_APP_POWER_KEY,
          "x-domain-agent": process.env.REACT_APP_DOMAIN_AGENT,
          "x-strict-origin-name": process.env.REACT_APP_ORIGIN_NAME,
          "x-range-name": process.env.REACT_APP_RANGE_NAME,
        },
        body: JSON.stringify({
          query,
          variables: {
            walletAddress: address.toString().toLowerCase(),
            product: id,
          },
        }),
      })
        .then((response) => {
          const json = response.json();
          return json;
        })
        .then(async (data) => {
          // console.log("Product", data.data.getProductDetailsByReviewer);
          if (data?.data?.getProductDetailsByReviewer === null) {
            setInvalidProduct(true);
          } else if (data?.data?.getProductDetailsByReviewer) {
            await setProductData(data.data.getProductDetailsByReviewer);
            await checkDisabledItems(data.data.getProductDetailsByReviewer);
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  const backToReview2Earn = () => {
    navigate("/review2earn");
  };

  return (
    <div>
      <Header />
      {address === undefined ? (
        <>
          <br />
          <br />
          <br />
          <br />
          <h6 className="text-center">
            Please Connect Your Wallet to Validate
          </h6>
          <br />
          <br />
        </>
      ) : (
        <section className="section validate-page">
          <div className="container">
            {!user.level_cleared ? (
              <h4
                className="text-center text-warning validate-text grow"
                onClick={handleValidatePage}
                style={{ cursor: "pointer" }}
              >
                Click here to clear the Test to validate this product
              </h4>
            ) : (
              ""
            )}
            <span class="list-inline-item mb-0 ">
              <button
                onClick={backToReview2Earn}
                class="btn btn-primary btn-th"
              >
                <i
                  class="uil uil-angle-double-left align-middle "
                  title="twitter"
                ></i>
                Back
              </button>
            </span>
            &nbsp;
            {productData.validated ? (
              <div style={{ fontSize: "20px" }}>
                <p
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    fontSize: "20px",
                    color: "lime",
                  }}
                >
                  You have already Validated this Product
                </p>
              </div>
            ) : (
              ""
            )}
            <div className="row border-bottom pb-4">
              <div className="logo-div validate">
                <img
                  src={productData.product_logo}
                  className="logo-validate"
                  alt=""
                  style={{ float: "right" }}
                />
              </div>
              <div className="col-lg-5 col-md-4 col-12">
                <div className="features feature-primary">
                  <div className="content mt-4">
                    <h5 className="text-muted qs">
                      Project Name :{" "}
                      <span className="ans-validate">
                        {productData.product_name}
                      </span>{" "}
                    </h5>
                    <h5 className="text-muted qs">
                      One Line Description :{" "}
                      <span className="ans-validate">
                        {productData.one_line_description}
                      </span>{" "}
                    </h5>
                    <h5 className="text-muted qs">
                      Product Type :{" "}
                      <span className="ans-validate">
                        {productData.product_type}
                      </span>{" "}
                    </h5>
                  </div>
                </div>
              </div>

              <div className="col-lg-3 col-md-4 col-12">
                <div className="features feature-primary">
                  <div className="content mt-4">
                    <h5 className="text-muted qs">
                      Category :{" "}
                      <span className="ans-validate">
                        {productData.category?.[0]?.value ?? "Not Provided"}
                      </span>{" "}
                    </h5>
                    <h5 className="text-muted qs">
                      White Paper :
                      {productData.whitepaper_link === null ? (
                        <span className="ans-validate"> Not Applicable</span>
                      ) : (
                        <a
                          href={productData.whitepaper_link}
                          target="_blank"
                          className="ans-validate"
                          rel="noreferrer"
                        >
                          {" "}
                          View{" "}
                        </a>
                      )}
                    </h5>

                    <h5 className="text-muted qs">
                      Pitch Deck :
                      {productData.pitch_deck_link === null ? (
                        <span className="ans-validate"> Not Applicable</span>
                      ) : (
                        <a
                          href={productData.pitch_deck_link}
                          target="_blank"
                          className="ans-validate"
                          rel="noreferrer"
                        >
                          {" "}
                          View{" "}
                        </a>
                      )}
                    </h5>
                  </div>
                </div>
              </div>

              <div className="col-lg-3 col-md-4 col-12">
                <div className="features feature-primary">
                  <div className="content mt-4">
                    <h5 className="text-muted qs">
                      Live Product :
                      {!productData.live_product_link ? (
                        <span className="ans-validate"> Not Applicable</span>
                      ) : (
                        <a
                          href={productData.live_product_link}
                          target="_blank"
                          className="ans-validate"
                          rel="noreferrer"
                        >
                          {" "}
                          View Product
                        </a>
                      )}
                    </h5>
                    <h5 className="text-muted qs">
                      Competition Links:{" "}
                      <span className="ans-validate">
                        {productData.competition_links?.[0] ? (
                          <a
                            href={productData.competition_links[0]}
                            target="_blank"
                            className="ans-validate"
                            rel="noreferrer"
                          >
                            View Product
                          </a>
                        ) : (
                          "Not Provided"
                        )}
                      </span>{" "}
                    </h5>

                    <h5 className="text-muted qs">
                      Revenue Module :{" "}
                      <span className="ans-validate">
                        {productData.revenue_model?.[0]?.value ??
                          "Not Provided"}
                      </span>{" "}
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="container mt-4 " style={{ marginTop: "50px" }}>
            <div className="row align-items-center">
              {/* <div className="col-lg-6 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0"> */}
              <div className="row border-bottom pb-2 ">
                <div className="section-title">
                  <h4 className="title validate-head-ans">Problem Statement</h4>
                  <ul className="ul-vhs">
                    {isValidArray(productData.problem_statement)
                      ? productData.problem_statement.map((value, index) => {
                          return <li>{value}</li>;
                        })
                      : "Not Provided"}
                  </ul>
                </div>
              </div>
              <br />
              <br />
              {/* </div> */}
              {/* <div className="col-lg-6 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0"> */}
              <div className="row border-bottom pb-2 mt-4">
                <div className="section-title">
                  <h4 className="title validate-head-ans">Solution</h4>
                  <ul className="ul-vhs">
                    {isValidArray(productData.problem_solution)
                      ? productData.problem_solution.map((value, index) => {
                          return <li>{value}</li>;
                        })
                      : "Not Provided"}
                  </ul>
                </div>
              </div>
              <br />
              <br />

              {/* </div>
            </div> */}
              {/* 
            <div className="container mt-100 mt-60">
                <div className="row align-items-center"> */}
              {/* <div className="col-lg-6 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0"> */}
              <div className="row border-bottom pb-2 mt-4">
                <div className="section-title">
                  <h4 className="title validate-head-ans">Target Audience</h4>
                  <ul className="ul-vhs">
                    {isValidArray(productData.target_customers)
                      ? productData.target_customers.map((value, index) => {
                          return <li>{value}</li>;
                        })
                      : "Not Provided"}
                  </ul>
                </div>
              </div>
              {/* </div> */}
              {/* <div className="col-lg-6 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0"> */}
              <div className="row border-bottom pb-2 mt-4">
                <div className="section-title">
                  <h4 className="title validate-head-ans">
                    Unique Selling Point
                  </h4>
                  <ul className="ul-vhs">
                    {isValidArray(productData.competition_reason)
                      ? productData.competition_reason.map((value, index) => {
                          return <li>{value}</li>;
                        })
                      : "Not Provided"}
                  </ul>
                </div>
              </div>
              {/* </div> */}
            </div>
          </div>

          {productData.validated && address !== undefined ? (
            ""
          ) : (
            <div className="validate-footer">
              <button className="btn btn-primary btn-th" onClick={handleShow}>
                Validate this Product
              </button>
            </div>
          )}

          <ProductValidate
            show={show}
            setShow={handleClose}
            ValidateIdea={showPopup}
            disabledItems={disabledItems}
          />

          <br />
          <br />
          <br />

          {validatePopup ? (
            <div
              className={` ${validatePopup ? "modal fade show" : ""}`}
              style={{
                display: validatePopup ? "block" : "none",
                background: "#000000C9",
              }}
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content rounded shadow border-0">
                  <div className="modal-body py-5">
                    {
                      <Link
                        to="/review2earn"
                        style={{ position: "absolute", top: "0", right: "0" }}
                        className="btn btn-icon btn-close btn-cus-close"
                      >
                        <i className="uil uil-times fs-4 text-dark"></i>
                      </Link>
                    }
                    <div className="text-center">
                      <div className="mt-0">
                        <>
                          <h4>Congrats 🎉 </h4>
                          <p
                            className="head-gr-modal mb-0"
                            style={{ fontSize: "22px" }}
                          >
                            You just crushed that product review and unlocked{" "}
                            <span style={{ color: "#00eb00" }}>50 xp</span>{" "}
                            reward!
                          </p>
                          {/* <p className="head-gr-modal mb-0" style={{fontSize:"22px"}}>Gonna be groovy 🥳</p> */}
                          <br />
                          <div className="mt-4">
                            <Link to="/review2earn" className="btn btn-warning">
                              Evaluate more
                            </Link>
                          </div>
                        </>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </section>
      )}
    </div>
  );
};

export default ProductDetail;
