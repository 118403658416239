import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { apiURI } from "../../config";
import { useAddress } from "@thirdweb-dev/react";
import { useConnectAddress } from "../../Context/WalletContext";

// Page Components
import Header from "../../Components/Header";

// Other Components
import { useSDK } from "@thirdweb-dev/react";
import Spinner from "react-bootstrap/Spinner";

const Quiz = ({ quizStarted, setQuizStarted, questions }) => {
  // Current Question to Mange state of Viewing Question
  // Initialize Question && questionIndexData to Create an array of 10 with managing the State of Questions
  // answers store answers of all the questions
  // setScore to store result from the Backend Api
  // quizStatus to Toggle Test Complete Pop-up

  // *********************************************************************VARIABLES******************************************************************************

  const [currentQuestion, setCurrentQuestion] = useState(0);
  const initializeQuestions = Array(10)
    .fill()
    .map(() => `Unattempted`);
  const [questionIndexData, setQuestionIndexData] =
    useState(initializeQuestions);

  const [answers, setAnswers] = useState([]);
  const [timer, setTimer] = useState(300);
  const [quizStatus, setQuizStatus] = useState(true);

  const [testResult, setTestResult] = useState();

  const navigate = useNavigate();
  const address = useAddress();
  const { updateUser } = useConnectAddress();

  //Timer Format options
  const minutes = Math.floor(timer / 60);
  const seconds = timer % 60 >= 10 ? timer % 60 : `0${timer % 60}`;
  //Submit Button Enable/Disable Variable
  const allQuestionsAnswered = questionIndexData.every(
    (answer) => answer !== "Unattempted"
  );

  //Sample Data Format
  //   const questions1 = [
  //     {
  //         index: "1",
  //         question: "What is the capital of India?",
  //         options: ["New Delhi", "Mumbai", "Bangalore", "Chennai"],
  //         answer: "New Delhi",
  //     },
  //   ];

  // Reduces the timing in seconds till it reaches the limit
  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimer((prevTimer) => {
        if (prevTimer !== 0) {
          return prevTimer - 1;
        } else {
          return prevTimer;
        }
      });
    }, 1000);
    return () => clearInterval(intervalId);
  }, []);

  // perform action when timer hits 0
  useEffect(() => {
    if (timer === 0) {
      // alert("Your Time has Expired")
      handleSubmit();
    }
  }, [timer]);

  // *********************************************************************FUNCTIONS******************************************************************************

  const handleNext = () => {
    setCurrentQuestion(currentQuestion + 1);
  };

  const handlePrev = () => {
    setCurrentQuestion(currentQuestion - 1);
  };

  // Set QuestionIndexData to Marked
  const handleMark = () => {
    const newData = [...questionIndexData];
    newData[currentQuestion] = "Marked";
    setQuestionIndexData(newData);
    currentQuestion < 9
      ? setCurrentQuestion(currentQuestion + 1)
      : setCurrentQuestion(0);
  };

  // Reset Question options and reset marked
  const handleClear = () => {
    const newAnswers = [...answers];
    newAnswers[currentQuestion] = undefined;
    setAnswers(newAnswers);
    const newData = [...questionIndexData];
    newData[currentQuestion] = "Unattempted";
    setQuestionIndexData(newData);
  };

  //set the option to answer array and  QuestionIndexData to Answered
  const handleOptionClick = (option) => {
    const newAnswers = [...answers];
    newAnswers[currentQuestion] = option;
    setAnswers(newAnswers);
    const newData = [...questionIndexData];
    newData[currentQuestion] = "Answered";
    setQuestionIndexData(newData);
  };

  // Navigating through the Question Numbers
  const handleQuestionChange = (index) => {
    setCurrentQuestion(index);
  };

  const handleSubmit = async () => {
    setTimer(0);

    const questionIndex = questions.map((value) => value.index);
    const answerIndex = answers.map((value) => value + 1);
    try {
      await getScore(questionIndex, answerIndex);
    } catch (error) {
      console.log("Error:", error);
    }

    //Setting CSS To Make Screen Fixed
    const body = document.querySelector("body");
    body.style.overflow = "hidden";
  };

  const handleSuccess = async (result) => {
    const body = document.querySelector("body");
    body.style.overflow = "";
    setQuizStarted(false);
    navigate("/review2earn");
  };

  const handleFailure = async (result) => {
    const body = document.querySelector("body");
    body.style.overflow = "";
    setQuizStarted(false);
  };

  /// API CAllS
  const getScore = async (questionIndex, answerIndex) => {
    try {
      var query = `
        query Query($input: Answer) {
            getScore(input: $input)
          }
            `;
      fetch(apiURI.URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "x-power": process.env.REACT_APP_POWER_KEY,
          "x-domain-agent": process.env.REACT_APP_DOMAIN_AGENT,
          "x-strict-origin-name": process.env.REACT_APP_ORIGIN_NAME,
          "x-range-name": process.env.REACT_APP_RANGE_NAME,
        },
        body: JSON.stringify({
          query,
          variables: {
            input: {
              questionIndex: questionIndex,
              answerIndex: answerIndex,
              wallet_address: address.toString().toLowerCase(),
            },
          },
        }),
      })
        .then((response) => {
          const json = response.json();
          return json;
        })
        .then(async (data) => {
          if (data?.data?.getScore) {
            setTestResult(data.data.getScore);
            setQuizStatus(false);
          }
          return 0;
        });
    } catch (error) {
      console.log(error);
    }
  };

  // *********************************************************************DESIGN******************************************************************************

  return (
    <>
      {!quizStarted ? (
        ""
      ) : (
        <div>
          <Header />
          <div className="container mt-4">
            <div className="row align-items-center">
              <div className="col-12 mb-4">
                <div className="sidebar-questions rounded shadow d-flex justify-content-between">
                  <div className="p-4">
                    <span style={{ paddingRight: "0.3px" }} />
                    {questionIndexData.map((value, index) => (
                      <>
                        <button
                          onClick={() => handleQuestionChange(index)}
                          className={
                            value === "Unattempted"
                              ? "btn btn-outline-primary"
                              : value === "Marked"
                              ? "btn btn-warning"
                              : "btn btn-success"
                          }
                        >
                          {" "}
                          {index + 1}{" "}
                        </button>{" "}
                        <span style={{ marginRight: "0.3px" }} />
                      </>
                    ))}
                  </div>
                  <div className="time p-4 float-left">
                    <h3 style={{ fontWeight: "600" }}>
                      Time Left : {minutes}:{seconds}{" "}
                    </h3>
                  </div>
                </div>
              </div>

              <div className="col-lg-12 col-md-12 order-2 order-md-0 mt-5 mt-sm-0 mb-5">
                <div className="section-title" style={{ height: "300px" }}>
                  <h1 className="title mb-5" style={{ fontWeight: "600" }}>
                    {currentQuestion + 1}.{questions[currentQuestion].question}
                  </h1>

                  {questions[currentQuestion].options.map((option, index) => (
                    <div className="custom-control custom-radio custom-control-inline">
                      <div className="form-check mb-2">
                        <input
                          style={{
                            border: "1px solid #2a3c51",
                            backgroundColor: "#1c2836",
                          }}
                          className="form-check-input"
                          checked={answers[currentQuestion] === index}
                          type="radio"
                          value={option}
                          name={option}
                          id={option}
                          onChange={() => handleOptionClick(index)}
                        />

                        <label className="form-check-label rd-c" for={option}>
                          {option}
                        </label>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="col-12 mb-0">
                <div className="component-wrapper rounded shadow justify-content-between">
                  <div className="p-4">
                    <button
                      disabled={currentQuestion === 0 ? true : false}
                      onClick={() => handlePrev()}
                      className="btn btn-primary button-responsive"
                    >
                      {" "}
                      Previous{" "}
                    </button>
                    <button
                      onClick={() => handleMark()}
                      className="btn btn-primary button-responsive"
                    >
                      {" "}
                      Mark for Review & Next{" "}
                    </button>
                    <button
                      onClick={() => handleClear()}
                      className="btn btn-primary button-responsive"
                    >
                      {" "}
                      Clear Response{" "}
                    </button>
                    <button
                      disabled={currentQuestion === 9 ? true : false}
                      onClick={() => handleNext()}
                      className="btn btn-primary button-responsive"
                    >
                      {" "}
                      Next{" "}
                    </button>
                    <button
                      style={{
                        display: allQuestionsAnswered ? "" : "none",
                        float: "right",
                      }}
                      onClick={() => handleSubmit()}
                      className="btn btn-primary button-responsive btn-th"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <footer className="footer  ft-re">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="footer-py-30 footer-border">
                    <div className="container">
                      <div className="row">
                        <div className="col-lg-3">
                          <div className="align-items-center justify-content-center text-center">
                            <p className="value-mark">
                              {
                                questionIndexData.filter(
                                  (str) => str === "Answered"
                                ).length
                              }
                            </p>
                            <h6 className="mb-0" style={{ fontWeight: "600" }}>
                              {" "}
                              Answered
                            </h6>
                          </div>
                        </div>

                        <div className="col-lg-3">
                          <div className="align-items-center justify-content-center text-center">
                            <p className="value-mark">
                              {
                                questionIndexData.filter(
                                  (str) => str === "Unattempted"
                                ).length
                              }
                            </p>
                            <h6 className="mb-0" style={{ fontWeight: "600" }}>
                              Unattempted
                            </h6>
                          </div>
                        </div>

                        <div className="col-lg-3">
                          <div className="align-items-center justify-content-center text-center">
                            <p className="value-mark">
                              {
                                questionIndexData.filter(
                                  (str) => str === "Marked"
                                ).length
                              }
                            </p>
                            <h6 className="mb-0" style={{ fontWeight: "600" }}>
                              Marked
                            </h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </footer>

          {quizStatus ? (
            ""
          ) : (
            <div
              className={` ${!quizStatus ? "modal fade show" : ""}`}
              style={{
                display: !quizStatus ? "block" : "none",
                background: "#000000C9",
              }}
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content rounded shadow border-0">
                  <div className="modal-body py-5">
                    <div className="text-center">
                      <div className="mt-0">
                        {testResult === "Pass" ? (
                          <>
                            <p className="head-gr-modal mb-0">
                              {" "}
                              Whoa, You have cleared the test now! 🥳
                            </p>
                            <p className="text-muted">
                              Congrats on crushing that test like a boss
                            </p>
                            <div className="mt-4">
                              <button
                                onClick={handleSuccess}
                                className="btn btn-primary btn-th"
                              >
                                Review2Earn
                              </button>
                            </div>
                          </>
                        ) : (
                          <>
                            <p className="head-gr-modal mb-0">
                              You missed the funky beat 🥺
                            </p>
                            <p className="text-muted">
                              Try to hit the bull's eye again
                            </p>
                            <div className="mt-4">
                              <button
                                onClick={handleFailure}
                                className="btn btn-primary btn-th"
                              >
                                Retry
                              </button>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default Quiz;
