import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { apiURI } from "../../config";

import AllProducts from "./AllProducts";
import { useNavigate } from "react-router-dom";
import ProductCard from "./ProductCard";

const Products = () => {
  const navigate = useNavigate();

  //   const [featuredIdeas, setFeaturedIdeas] = useState([]);
  const [recentProducts, setRecentProducts] = useState([]);
  const [allProducts, setAllProducts] = useState([]);

  var settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    // slidesToScroll: 1,

    swipeToSlide: true,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
          infinite: true,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          arrows: false,
          // infinite: true,
          // dots:true
        },
      },
    ],
  };

  const getProductsData = async () => {
    try {
      var query = `
        query RecentIdeas($reviewer: String, $recentProductsReviewer: String) {
          allProductsReviewer(reviewer: $reviewer) {
                product_name
                product_logo
                one_line_description
                category {
                value
                }
                product_type
                whitepaper_link
                live_product_link
                linkedin_link
                telegram_link
                twitter_link
                _id
          }
          recentProducts(reviewer: $recentProductsReviewer) {
                product_name
                product_logo
                one_line_description
                category {
                value
                }
                product_type
                whitepaper_link
                live_product_link
                linkedin_link
                telegram_link
                twitter_link
                _id
          }
        }
              `;
      await fetch(apiURI.URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "x-power": process.env.REACT_APP_POWER_KEY,
          "x-domain-agent": process.env.REACT_APP_DOMAIN_AGENT,
          "x-strict-origin-name": process.env.REACT_APP_ORIGIN_NAME,
          "x-range-name": process.env.REACT_APP_RANGE_NAME,
        },
        body: JSON.stringify({
          query,
          variables: {
            reviewer: "test",
            recentProductsReviewer: "test",
          },
        }),
      })
        .then((response) => {
          const json = response.json();
          return json;
        })
        .then(async (data) => {
          if (data?.data?.recentProducts) {
            setRecentProducts(data.data.recentProducts);
          }
          if (data?.data?.allProductsReviewer) {
            setAllProducts(data.data.allProductsReviewer);
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      // await new Promise(resolve => setTimeout(resolve, 1000)); // Wait for 10 seconds before making the API call
      await getProductsData(); // Make the API call
    };
    fetchData();
  }, []);

  return (
    <div>
      {/* <Slider {...settings}>
        {featuredIdeas.map((value, index) => {
          // {recentIdeas.map((value, index) => {
          return <ProductCard data={value} />;
        })}
      </Slider> */}

      <div class="row pt-0">
        <div class="col-12">
          <div class="tab-content" id="pills-tabContent">
            <div
              class="tab-pane fade show active"
              id="pills-cloud"
              role="tabpanel"
              aria-labelledby="pills-cloud-tab"
            >
              <div class="row pb-4 border-bottom">
                <div
                  class="mb-0 mt-5"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <h6>Recent Products&nbsp;</h6>
                </div>
                <Slider {...settings}>
                  {recentProducts.map((value, index) => {
                    return <ProductCard data={value} />;
                  })}
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* All Ideas */}

      <AllProducts allProducts={allProducts} />
    </div>
  );
};

export default Products;
